<template>
  <Modal class="modal" :width="`50%`">
    <template #body>
      <ContentCardFull>
        <template #title>
          <div class="flex flex-column title">
            <div class="flex justify-content-between align-items-center">
              <h3 class="light-blue">Plan Notes</h3>
              <Button class="clarity-close-modal-btn" @click="closeModal"><span
                  class="material-icons-round grey">close</span></Button>
            </div>
          </div>
        </template>
        <template #content-full>

          <DataTable :value="notes" class="rd-table">
            <Column field="created_at" header="Date">
              <template #body="slotProps">
                <div class="flex flex-column">
                  <span>{{ getDate(slotProps.data.created_at) }}</span>
                </div>
              </template>
            </Column>
            <Column field="user_uuid" header="From">
                <template #body="slotProps">
                    <span>{{ noteName(slotProps.data.user_uuid) }}</span>
                </template>
            </Column>
            <Column field="note" header="Note"></Column>
            <template #empty>
              <div class="flex justify-content-center">No notes found.</div>
            </template>
          </DataTable>
        </template>
      </ContentCardFull>
    </template>
  </Modal>
</template>

<script>
import {useStore} from "vuex";
import {computed} from "vue";
import dayjs from "dayjs";
import ContentCardFull from '@/components/common/ContentCardFull'
import Modal from '@/components/common/Modal'


export default {
  name: "WealthPlanNotesModal",
  components: {ContentCardFull, Modal},
  emits: ['closeModal'],
  setup(props, context) {
    const store = useStore()

    const closeModal = () => {
      context.emit('closeModal')
    }

    const notes = computed(() => store.getters.getWealthplanNotes)

    const getDate = (timestamp) => {
      return dayjs(timestamp).format('DD/MM/YYYY')
    }
    const noteName = (uuid) => {
        const name = store.getters.personFromUuid(uuid);
        return name.name ? name.name : 'clarity';
    }

    return {
      closeModal,
      getDate,
      notes,
      noteName
    }
  }
}
</script>

<style scoped>
.title {
  padding: 30px;
  padding-bottom: 0;
}
div:deep(.modal-close) {
  display: none;
}
</style>
