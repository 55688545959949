<template>
  <content-card-full>
    <template #title>
      <card-title>
        <template #title><h2 class="title">WealthPlan</h2></template>
      </card-title>
    </template>
    <template #content-full>
      <div class="flex">
        <!-- LEFT -->
        <div class="flex">
          <div class="flex flex-column justify-content-between">
            <div>
              <h3 class="mb-3">Introduction</h3>
              <div class="body pr-5">
                <p>Once your clarity adviser has created your WealthPlan(s), it/they will show here. You can then access
                  it securely at any time, amend any of the details or assumptions used, and even create alternative
                  versions to compare and contrast.</p>
                <p>If you receive strategic/lifestyle financial planning services from clarity and do not yet have a
                  WealthPlan, please contact your usual clarity adviser.</p>
              </div>
              <div class="body pr-5">
                <h5 class="blue">Not yet a clarity client?</h5>
                <p>Unlike many of the tools with clarityONLINE, WealthPlan is currently only available to clients who
                  use our ongoing financial planning services. If you would like to discuss how WealthPlan and our
                  strategic financial planning advice could benefit you, please don’t hesitate to contact us on 0800 368
                  7511 or <a class="clarity-light-blue-text underline" href="mailto:enquiries@clarityglobal.com">enquiries@clarityglobal.com.</a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <spacer-h/>

      <div>
        <WealthPlanTable :data="wealthplans" :meta="meta" :loading="loading"/>
      </div>
    </template>
  </content-card-full>

  <div v-if="showModal.notes">
    <WealthPlanNotesModal v-model:visible="showModal.notes" id="wealthplanNotes" @closeModal="toggleDialog()"/>
  </div>

</template>

<script>
import CardTitle from '@/components/common/CardTitle.vue'
import ContentCardFull from '@/components/common/ContentCardFull.vue'
import WealthPlanTable from "@/components/tools/WealthPlanTable";
import SpacerH from '@/components/common/layout/SpacerH.vue'
import api from "@/core/services/api";
import {toDrupalRequest} from "@/core/helpers";
import {computed, onMounted, ref, reactive, watch} from "vue";
import WealthPlanNotesModal from "@/components/modals/WealthPlanNotesModal";
import {useStore} from "vuex";

export default {
  name: "TabWealthPlan",
  setup() {
    const store = useStore()
    const plans = ref([])
    const meta = ref({})
    const loading = ref(true)
    const showModal = reactive({
      notes: false,
    })
    const activeModal = computed(() => store.getters.currentActiveModal);

    watch(activeModal, val => {
      showModal.notes = val === 'wealthplannotes'
    })

    const whoAmI = computed(() => {
        if(process.env.VUE_APP_TARGET_OVERRIDE === 'true') {
            if(store.getters.targetUUID){
                return store.getters.targetUUID;
            }
        }
        return store.getters.clarityUUID
    });
    const getPlans = async () => {
      loading.value = true
      const wealthplanEndpoint = '/wealthplan/plans?clarity_user_id='+whoAmI.value+'&displayType=me&keywords=&pageIndex=1&pageSize=100'
      const response = await api.get(`${toDrupalRequest(wealthplanEndpoint)}`)

      if (response) {
        plans.value = response.list
        meta.value = { "totalCount": response.total }
        loading.value = false
      }
    }

    onMounted(() => {
      getPlans()
    })

    const toggleDialog = () => {
      store.dispatch('setActiveModal')
      store.dispatch('resetWealthplanNotes')
    }

    return {
      wealthplans: computed(() => plans.value),
      meta: computed(() => meta.value),
      loading,
      showModal,
      toggleDialog
    }
  },
  components: {
    WealthPlanNotesModal,
    WealthPlanTable,
    CardTitle,
    ContentCardFull,
    SpacerH
  },
}
</script>

<style scoped>
.body {
  font-size: 18px;
  line-height: 28px;
}
</style>
