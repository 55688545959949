<template>
  <div>
    <h3 class="my-3">Your Wealthplans</h3>
    <div class="flex justify-content-between pb-5">

      <div class="half pr-5">
      <span class="p-input-icon-left cl-input full-width">
        <i class="pi pi-search"></i>
        <InputText type="text" v-model="filters.global.value" placeholder="Search for a plan..." class="p-inputtext-lg w-full"
                   data-cy="wealthplan-search"/>
      </span>
      </div>
    </div>
    <DataTable :value="plans" class="rd-table" dataKey="id" ref="dt" :paginator="true" :rows="rows"
               :loading="loading" :filters="filters" :globalFilterFields="['name']" sortField="published_at" :sortOrder="-1">
      <Column field="name" header="Name" key="name" filterField="name" :sortable="true" style="width: 40%;">
        <template #body="slotProps">
          <h3 class="light-blue">{{ slotProps.data.name }}</h3>
        </template>
      </Column>
      <Column field="published_at" header="Published Date" key="published"
              :sortable="true"  style="width: 15%;">
        <template #body="slotProps">
          <div class="flex flex-column">
            <span>{{ getDate(slotProps.data.published_at) }}</span>
          </div>
        </template>
      </Column>
      <Column field="updated_at" header="Last Updated" key="updated" :sortable="true" style="width: 15%;">
        <template #body="slotProps">
          <div class="flex flex-column">
            <span>{{ getDate(slotProps.data.updated_at) }}</span>
          </div>
        </template>
      </Column>
      <Column field="actions" header="Actions" key="action" style="min-width: 20%;">
        <template #body="slotProps">
          <div class="flex align-items-center justify-content-evenly">
            <a :href="wealthplanEndPoint+'wealth-plan/'+slotProps.data.id" target="_blank"><Button class="clarity-btn clarity-gradient-orange"> View Plan</Button></a>
            <Button class="clarity-btn clarity-gradient-grey" @click="viewNotes(slotProps.data.id)"> Plan Notes</Button>
          </div>
        </template>
      </Column>
      <template #paginatorstart>
        <!-- Needed to centralise paginator -->
      </template>
      <template #paginatorend>
        <div class="my-2"><span class="mr-2">Plans Per Page:</span>
          <Dropdown v-model="rows" :options="[5,10,20]" class="pagination-options"/>
        </div>
      </template>
      <template #empty>
        <div class="flex justify-content-center">No wealthplans found.</div>
      </template>
    </DataTable>
  </div>
</template>

<script>
import {computed, onMounted, ref, watch} from "vue";
import dayjs from "dayjs";
import api from "@/core/services/api";
import {toDrupalRequest} from "@/core/helpers";
import {useStore} from "vuex";

export default {
  name: "WealthPlanTable",
  props: ['data', 'meta', 'loading'],
  setup(props) {
    const store = useStore()
    const loading = ref(props.loading)
    const rows = ref(10)
    const plans = ref(props.data ?? [])
    const notes = ref([])
    const totalRecords = ref(props.meta.totalCount ?? 0)
    const wealthplanEndPoint = process.env.VUE_APP_WEALTHPLANENDPOINT;

    const filters = ref({
      'global': {value: '', matchMode: 'contains'}
    })

    onMounted(() => {
        getPeopleOptions()
    })

    const getPeopleOptions = () => {
        store.dispatch('getPeopleOptions')
    }

    const getDate = (timestamp) => {
      return dayjs(timestamp).format('DD/MM/YYYY')
    }

    watch(props, () => {
      plans.value = props.data
      totalRecords.value = props.meta.totalCount
      loading.value = props.loading
    })

    const viewNotes = async (id) => {
      await store.dispatch('resetWealthplanNotes')

      const notesEndpoint = `/wealthplan/plans/notes/${id}`
      const response = await api.get(`${toDrupalRequest(notesEndpoint)}`)

      if (response) {
        notes.value = response.list
        await store.dispatch('setWealthplanNotes', notes.value)
        await store.dispatch('setActiveModal', {modal: 'wealthplannotes'})
      }

    }

    return {
      filters,
      plans: computed(() => plans.value),
      rows,
      totalRecords: computed(() => totalRecords.value),
      getDate,
      viewNotes,
      wealthplanEndPoint
    }
  }
}
</script>

<style scoped>
.half {
  width: 30%;
}

button.clarity-btn {
  padding: 15px 45px;
}
</style>
